<template>
  <div v-loading="loading">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="time" label="购买时间"></el-table-column>
      <el-table-column prop="id" label="订单ID"></el-table-column>
      <el-table-column prop="number" label="订单号"></el-table-column>
      <el-table-column prop="integral" label="积分流动"></el-table-column>
      <el-table-column prop="sintegral" label="剩余积分"></el-table-column>
      <el-table-column prop="amount" label="消费金额"></el-table-column>
      <el-table-column prop="package" label="购买套餐"></el-table-column>
      <el-table-column prop="term" label="套餐期限"></el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { getToken, setToken, removeToken } from "@/utils/setToken.js";
import axios from 'axios'

export default {
  data() {
    return {
      loading: false,
      tableData: [],
      currentPage: 1,
      pageSize: 20,
      total: 0
    };
  },
  created() {
    // 获取token和userid
    const usertoken = getToken('token');

    if (usertoken) {
      this.setData();
      this.getData();
    } else {
      removeToken('token');
      this.$router.push('/login');
      this.$message({
        message: "无效的token，请重新登录",
        type: "error",
      });
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },

    setData(params) {
      const usertoken = getToken('token');
      axios
        .get('https://cf-v1.uapis.cn/api/userinfo.php', {
          params: { usertoken, ...params },
        })
        .then(response => {
          if (response.data.error) {
            removeToken('token');
            this.$router.push('/login');
            this.$message({
              message: "无效的token，请重新登录",
              type: "error",
            });
          } else {
            const { userid, username, email, qq, usergroup, bandwidth, tunnel, realname, integral, userimg, term, tunnelstate } = response.data;

            setToken('userid', userid);
            setToken('username', username);
            setToken('email', email);
            setToken('qq', qq);
            setToken('usergroup', usergroup);
            setToken('bandwidth', bandwidth);
            setToken('tunnel', tunnel);
            setToken('realname', realname);
            setToken('integral', integral);
            setToken('userimg', userimg);
            setToken('term', term);
            setToken('tunnelstate', tunnelstate);
          }
        })
        .catch(error => {
          console.error('请求API时发生错误:', error);
        })
    },

    getData(params) {
      this.loading = true;
      const userid = getToken("userid");
      axios
        .get("https://cf-v1.uapis.cn/api/query.php", {
          params: { userid, ...params, page: this.currentPage, pagesize: this.pageSize },
        })
        .then((response) => {
          this.tableData = response.data.row;
          this.total = response.data.totalCount;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        })
    },
  }
};
</script>
  
<style lang="less">
</style>